import Vue from 'vue';

import { AlbumModel } from '@/models/user.model';
import Loader from '@/components/Shared/Loader/Loader';
import '../ClientView.css';
import { loadAlbumQuery } from '@/services/album';
import PictureList from '@/components/Shared/PictureList/PictureList';

export interface ClientAlbumDetailData {
  album: AlbumModel | null;
  albumId: string;
  loading: boolean;
}

const ClientAlbumDetail = Vue.extend({
  data(): ClientAlbumDetailData {
    return {
      album: null,
      albumId: '',
      loading: true,
    };
  },

  methods: {
    loadAlbum() {
      this.loading = true;

      const { id: albumId } = this.$route.params;

      this.albumId = albumId;

      loadAlbumQuery(albumId)
        .then(({ data }) => {
          this.album = data?.getAlbum;
        })
        .catch((err) => {
          // TODO: Handle error
          console.log('Error loading album => ', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.loadAlbum();
  },

  name: 'client-album-detail',

  render() {
    if (this.loading) {
      return (
        <Loader />
      );
    }

    if (!this.album) {
      return (
        <h1>Error loading album details.</h1>
      );
    }

    return (
      <div class="client-view">

        <div class="logo">
          <router-link to="/client/albums">
            <img src="https://res.cloudinary.com/nerdge/image/upload/q_auto,f_auto,fl_lossy,dpr_auto/v1582663321/Teefal%20Studios/logo.png" alt="Teefal Logo" />
          </router-link>
        </div>

        <header class="header">
          <h1 class="title">{this.album.name}</h1>

          <div class="icon-pairs">
            <div class="icon-pair">
              <img class="icon-pair-image" src={require('@/components/Icons/link.png')} alt="Link icon" />
              <span class="icon-pair-value">{this.album.pictures?.length || 0}</span>
              <span class="icon-pair-title">Pictures</span>
            </div>
          </div>
        </header>

        <div class="content">

          <PictureList
            pictures={this.album.pictures}
            showActions
            actions={{
              download: true,
            }}
          />

        </div>

      </div>
    );
  },

  watch: {
    $route: 'loadAlbum',
  },
});


export default ClientAlbumDetail;
